import React, { memo, useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import { CategoryPage } from '../CategoryPage/Loadable';

import LayoutWrapper from './Layout';
import { HomePage } from '../HomePage/Loadable';
import { NewsPage } from '../NewsPage/Loadable';
import { DieuKhoan } from '../DieuKhoan'
import { ChinhSach } from '../ChinhSach';
import { NewsDetailPage } from '../NewsDetailPage/Loadable';
import Login from '../LoginPage';
import { NapThe } from '../NapThe/Loadable'
import { ListOrder } from '../ListOrder';
import { PaymentSuccess } from '../PaymentSuccess/Loadable';
import { PaymentFalse } from '../PaymentFalse/Loadable';
import { ListAgency } from '../ListAgency/Loadable';
const Layout = () => {

  return (
    <LayoutWrapper>
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/?token=/:tokenApp" element={<HomePage />} />
        <Route path="/nap-the/:code" element={<NapThe />} />
        <Route path="/lich-su-giao-dich" element={<ListOrder />} />
        <Route path="/payment-detail/:code" element={<PaymentSuccess />} />
        <Route path="/dai-ly" element={<ListAgency />} />
        {/* <Route path="/payment-false" element={<PaymentFalse />} /> */}
        {/* <Route path='/ticket' element={<TicketSelect />} />
        <Route path='/category' element={<CategoryPage />} />
        <Route path='/send-ticket' element={<SendTicket />} />
        <Route path='/your-tickets' element={<YourTickets />} />
        <Route path='/news' element={<NewsPage />} />
        <Route path='/dieu-khoan' element={<DieuKhoan />} />
        <Route path='/chinh-sach' element={<ChinhSach />} />
        <Route path='/news-detail' element={<NewsDetailPage />} /> */}
        {/* <Route path={'/'} element={<HomePage />} /> */}
      </Routes>
    </LayoutWrapper>
  );
};

export default memo(Layout);
