import {
    AlignLeftOutlined
} from '@ant-design/icons';
import { Layout, Row, Typography } from 'antd';
import React, { memo } from 'react';
import { bg } from 'themes';
import FooterStyle from './Footer.style';
import { useNavigate } from 'react-router-dom';



const FooterLayout = () => {
    const { Header, Content, Footer, Sider } = Layout;
    const { Title } = Typography;
    const navigate = useNavigate();

    return (
        <FooterStyle>
            <Footer className='footer' style={{ backgroundImage: `url(${bg})`, backgroundRepeat: 'repeat-x' }}>
                <div className="" style={{ maxWidth: '1140px', margin: 'auto' }}>
                    <Row justify="center" align={'middle'}>
                        {/* <AlignLeftOutlined style={{ fontSize: 20 }} /> */}
                        <Title style={{ fontWeight: 'normal', fontSize: 14 }}>
                            Copyright Tepaylink
                        </Title>
                    </Row>
                    <Row justify="center" align={'middle'}>
                        <a className="" href='https://support.tepaylink.vn/dieu-khoan' target='_blank'>
                            <Title style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(69, 130, 192)' }}>
                                Điều khoản sử dụng
                            </Title>
                        </a>
                        <Title style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(69, 130, 192)', paddingLeft: 8, paddingRight: 8 }}>
                            |
                        </Title>
                        <a className="" href='https://support.tepaylink.vn/chinh-sach' target='_blank'>
                            <Title style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(69, 130, 192)' }}>
                                {` `}Chính sách bảo mật
                            </Title>
                        </a>
                        <Title style={{ fontWeight: 'normal', fontSize: 'calc(14px)', color: 'rgb(69, 130, 192)', paddingLeft: 8, paddingRight: 8 }}>
                            |
                        </Title>
                        <a className="" href='https://support.tepaylink.vn/' target='_blank'>
                            <Title style={{ fontWeight: 'normal', fontSize: 14, color: 'rgb(69, 130, 192)' }}>
                                Hỗ trợ
                            </Title>
                        </a>
                    </Row>
                </div>
            </Footer>
        </FooterStyle>
    )
};

export default memo(FooterLayout);