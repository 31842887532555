import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { globalSliceSaga } from "./saga";
import { GlobalSliceState } from "./types";

export const initialState: GlobalSliceState = {
  inited: false,
  accessToken: '',
  email: '',
  gameId: '',
  categoryId: '',
  categoryName: '',
  categoryTypeId: '',
  userName: ''
};

const slice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    someAction(state, action: PayloadAction<any>) { },

    saveUserName(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          userName: action.payload
        }
      }
      const { userName } = action.payload
      console.log('action__saveUserName', action.payload)
      return {
        ...state,
        inited: true,
        userName: userName || action.payload
      }
    },


    saveAccessToken(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          accessToken: action.payload
        }
      }
      const { accessToken } = action.payload
      // console.log('action__saveAccessToken', action.payload)
      return {
        ...state,
        inited: true,
        accessToken: accessToken
      }
    },

    saveGameId(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          gameId: action.payload
        }
      }
      const { gameId } = action.payload
      console.log('action__saveGameId', action.payload)
      return {
        ...state,
        inited: true,
        gameId: gameId
      }
    },

    saveCategoryId(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          categoryId: action.payload
        }
      }
      const { categoryId } = action.payload
      console.log('action___saveCategoryID', action.payload)
      return {
        ...state,
        inited: true,
        categoryId: categoryId
      }
    },

    saveCategoryName(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          categoryName: action.payload
        }
      }
      const { categoryName } = action.payload
      console.log('action___saveCategoryName', action.payload)
      return {
        ...state,
        inited: true,
        categoryName: categoryName
      }
    },

    saveCategoryTypeId(state, action: PayloadAction<any>) {
      if (!action.payload) {
        return {
          ...state,
          inited: true,
          categoryTypeId: action.payload
        }
      }
      const { categoryTypeId } = action.payload
      console.log('action___SaveCategoryType', action.payload)
      return {
        ...state,
        inited: true,
        categoryTypeId: categoryTypeId
      }
    }

  },
});

export const { actions: Actions } = slice;

export const useGlobalSliceSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: globalSliceSaga });
  return { actions: slice.actions };
};

export const { actions: globalSliceActions } = slice;


/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
