import styled from 'styled-components';

export default styled.div`
    cursor: pointer;

    .footer {
        background: white;
        padding: 24px 16px
        // position: absolute;
        // width: 150vw;
        // left: -10vw;
        // bottom: 0px;
    }

    @media (max-width: 840px) {
    
    }
`;
