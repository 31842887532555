import { EyeInvisibleOutlined, EyeTwoTone, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Typography, notification } from 'antd';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import LoginWrapper from './Login.style';
import { showAlert } from 'utils/helper';
import { login } from 'app/api/actions/authen';
import { useMutation } from 'react-fetching-library';
import { error } from 'console';
import { useGlobalSliceSlice } from '../../slice';
import { bg_login, logo_tpl, logo_web } from 'themes';
import { useParams, useRoutes } from 'react-router-dom';

const { Text } = Typography;
const key_login = 'login';

const Login = () => {
    // useInjectReducer({ key: key_login, reducer: reducerLogin });
    // useInjectSaga({ key: key_login, saga: sagaLogin });
    const { actions } = useGlobalSliceSlice();

    const navigate = useNavigate();
    const location: any = useLocation();
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [api, contextHolder] = notification.useNotification();
    const { state } = useLocation() as any

    const { mutate, loading } = useMutation(login)

    const openNoti = useCallback((type: string, message: string) => {
        api[type]({
            key: 'updatable',
            message: 'Thông báo',
            description: message || 'Không thành công, vui lòng thử lại',
        })
    }, [])

    const onFinish = useCallback(
        async (value: any) => {
            let { username, password } = value;
            // console.log({ username, password })
            // dispatch(login({ username, password }));
            try {
                let { payload } = await mutate({
                    UserName: username,
                    Password: password,
                })
                if (payload?.results?.isSuccess == true) {
                    localStorage.setItem('token', payload?.results?.data?.token?.access_token)
                    localStorage.setItem('username', username)
                    dispatch(actions.saveAccessToken(payload?.results?.data?.token?.access_token))
                    dispatch(actions.saveUserName(username))
                    console.log('state___', state)
                    navigate('/');
                    return
                }
                if (!payload?.results?.isSuccess) {
                    openNoti('error', payload?.results?.message)
                }
            } catch (error) {
                console.log('error___', error)
            }
        }, [state]
    );

    const validatePassword = (rule, value, callback) => {
        try {
            callback();
        } catch (err) {
            callback(err)
        }
    };

    return (
        <LoginWrapper>
            {contextHolder}
            <Helmet
                titleTemplate="Đăng nhập - CRM"
                defaultTitle="Đăng nhập - CRM"
            >
                <meta name="description" content="Đăng nhập - CRM" />
            </Helmet>
            <Row >
                <Col xs={24} md={24} lg={24} className="col-login">
                    <Card className="card" style={{ position: 'relative', overflow: 'hidden' }}>
                        <Col xs={24} md={24} lg={24} style={{ background: 'rgb(255 255 255/.6)', padding: 16 }}>
                            <Col xs={24} md={24} lg={24} style={{ display: 'flex', flexDirection: 'column' }}>
                                {/* <Text className="txt-title-brand">TEPAYLINK,. JSC</Text> */}
                                <img src={logo_web} style={{ maxWidth: 120, margin: 'auto' }} />
                            </Col>
                            <Form
                                form={form}
                                onFinish={onFinish}
                                className="form-brand"
                                layout="vertical"
                            >
                                <Form.Item
                                    name="username"
                                    label="Tài khoản"
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập tên đăng nhập' }
                                    ]}
                                >
                                    <Input
                                        className="input-item"
                                        placeholder="Nhập tài khoản *"
                                        disabled={loading}
                                        size="large"
                                        prefix={<UserOutlined style={{ marginRight: 4 }} />}
                                        allowClear
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[
                                        { required: true, message: 'Vui lòng nhập mật khẩu' },
                                        { validator: validatePassword }
                                    ]}
                                >
                                    <Input.Password
                                        className="input-item"
                                        placeholder="Password *"
                                        disabled={loading}
                                        prefix={<SettingOutlined style={{ marginRight: 4 }} />}
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        className="btn-brand btn-login"
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                    >
                                        Đăng nhập
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </LoginWrapper >
    );
};

export default Login;