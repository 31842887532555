import {
    LogoutOutlined, MenuUnfoldOutlined, UserDeleteOutlined, SmileOutlined
} from '@ant-design/icons';
import { Breadcrumb, BreadcrumbProps, Button, Drawer, DrawerProps, Dropdown, MenuProps, Space, Typography, notification } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useGlobalSliceSlice } from 'app/slice';
import { selectGlobalSlice } from 'app/slice/selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import HeaderStyle from './Header.style';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { logo_tpl_nap, logo_web } from 'themes';
import './style.css';

const HeaderLayout = () => {
    // const { query, payload, loading } = useParameterizedQuery(listGame)
    const usernameLocal = localStorage.getItem('username')

    const [api, contextHolder] = notification.useNotification();

    const token = localStorage.getItem('token')
    const gameIdLocal = localStorage.getItem('gameId')
    const { gameId } = useSelector(selectGlobalSlice)

    const { Title, Link } = Typography;
    const navigate = useNavigate()
    const [moreGame, setMoreGame] = React.useState<boolean>(false)
    const dispatch = useDispatch()
    const { actions } = useGlobalSliceSlice();
    const [placement, setPlacement] = useState<DrawerProps['placement']>('right');
    const [openDrawer, setOpenDrawer] = useState(false);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Button
                    onClick={e => {
                        // dispatch(logout())
                        localStorage.removeItem('token')
                        localStorage.removeItem('username')
                        localStorage.removeItem('gameId')
                        dispatch(actions.saveAccessToken({ token: "" }))
                        navigate('/');
                    }}
                    style={{ padding: 2, color: 'black' }} type='text' block>
                    <LogoutOutlined style={{ verticalAlign: 2, fontSize: 18, background: 'white', padding: 2, borderRadius: 4, color: 'black' }} />
                    Đăng xuất
                </Button>
            ),
        },
    ];

    return (
        <HeaderStyle >
            {contextHolder}
            <Header className='header-container' style={{ background: "#4C73A4" }}>
                <div className="header" style={{ background: "#4C73A4" }}>
                    <Link className='navTitle' href='/' style={{ color: 'white', fontWeight: 'bold', fontSize: 18 }}>
                        <img src={logo_web} style={{ margin: 'auto', maxWidth: 64 }} />
                    </Link>
                    <Space size={16}>
                        {/* <Link className='nav-button' style={{ color: 'white' }} href='/dai-ly'>Đại lý</Link> */}
                        {!!token ? <>
                            <Link className='nav-button' style={{ color: 'white' }} href='/'>Nạp thẻ</Link>
                            <Link className='nav-button' style={{ color: 'white' }} href='/lich-su-giao-dich'>Lịch sử giao dịch</Link>
                            <div className="header-drawer">
                                <Button
                                    style={{ padding: 2, color: 'white', fontWeight: 'bold' }} type='text' block onClick={() => setOpenDrawer(true)}>
                                    <UserDeleteOutlined style={{ verticalAlign: 2, fontSize: 18, background: 'white', padding: 2, borderRadius: 4, color: 'black' }} />
                                    Xin chào, {usernameLocal || ""}
                                </Button>
                                <Drawer
                                    title={'TEPAYLINK., JSC'}
                                    placement={placement}
                                    closable={false}
                                    onClose={() => setOpenDrawer(false)}
                                    open={openDrawer}
                                    key={placement}
                                >
                                    <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
                                        {/* <a style={{ textAlign: 'left', paddingBottom: 24, color: 'black', fontSize: 16 }} href='/dai-ly'>Đại lý</a> */}
                                        <a style={{ textAlign: 'left', paddingBottom: 24, color: 'black', fontSize: 16 }} href='/'>Nạp thẻ</a>
                                        <a style={{ textAlign: 'left', paddingBottom: 24, color: 'black', fontSize: 16 }} href='/lich-su-giao-dich'>Lịch sử giao dịch</a>
                                        <a onClick={e => {
                                            // dispatch(logout())
                                            localStorage.removeItem('token')
                                            localStorage.removeItem('username')
                                            localStorage.removeItem('gameId')
                                            dispatch(actions.saveAccessToken({ token: "" }))
                                            navigate('/');
                                        }} style={{ textAlign: 'left', paddingBottom: 24, color: 'black', fontSize: 16 }}>
                                            <LogoutOutlined style={{ verticalAlign: 2, fontSize: 18, background: 'white', padding: 2, borderRadius: 4, color: 'black', marginRight: 8 }} />
                                            Đăng xuất
                                        </a>

                                    </div>
                                </Drawer>
                            </div>
                            <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                                <Button
                                    className='nav-button'
                                    style={{ padding: 2, color: 'white' }} type='text' block>
                                    <UserDeleteOutlined style={{ verticalAlign: 2, fontSize: 18, background: 'white', padding: 2, borderRadius: 4, color: 'black' }} />
                                    Xin chào {usernameLocal || ""}
                                </Button>
                            </Dropdown>
                        </> : <Link href='/dang-nhap' style={{ color: 'white' }}> Đăng nhập</Link>
                        }

                    </Space>



                </div>


            </Header>
        </HeaderStyle>
    );
};

export default HeaderLayout;