import React from 'react';
import { toast } from 'react-toastify';

const optionAlert = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: 'colored'
} as any;

export const showAlert = {
    success: (mess) => {
        toast.success(
            <p style={{ fontSize: 14, margin: 0 }}>{mess}</p>,
            optionAlert
        );
    },
    warn: (mess) => {
        toast.warn(
            <p style={{ fontSize: 14, margin: 0 }}>{mess}</p>,
            optionAlert
        );
    },
    error: (mess) => {
        toast.error(
            <p style={{ fontSize: 14, margin: 0 }}>{mess}</p>,
            optionAlert
        );
    },
    info: (mess) => {
        toast.info(
            <p style={{ fontSize: 14, margin: 0 }}>{mess}</p>,
            optionAlert
        );
    }
};

export const formatNumberToCurrency = (n: any = 0, toFixed = 2) => {
    if (!n) {
        return 0;
    }
    let reg = /(\d)(?=(\d{3})+(?:\.\d+)?$)/g

    let number = parseFloat(n).toFixed(toFixed) as any;
    if (parseInt(n) - number == 0) {
        number = parseInt(n)
    }

    return number.toString().replace(reg, '$&,');
}
