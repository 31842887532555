/**
 *
 * ListOrder
 *
 */
import { Card, Layout, Row, Space, Table, TableProps, Typography, notification, Col } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { listOrder, listPackage } from 'app/api/actions/game';
import { selectGlobalSlice } from 'app/slice/selectors';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { useSelector } from 'react-redux';
import { formatNumberToCurrency } from 'utils/helper';
import ListWarapper from './ListOrder.style'
import { useNavigate } from 'react-router-dom';
import type { ExpandableConfig } from 'antd/es/table/interface';

interface Props { }

interface DataType {
  orderCode?: number;
  amount?: number;
  exchangeAmount?: number;
  receiver?: any;
  statusText?: any;
  createdTime?: any;
  game?: any,
  transaction?: any;
  status?: any,
  product?: any
}

export const ListOrder = memo((props: Props) => {
  const { Title, Text } = Typography;
  const { query, payload, loading } = useParameterizedQuery(listOrder)
  const { gameId, userName } = useSelector(selectGlobalSlice)
  const usernameLocal = localStorage.getItem('username')
  const navigate = useNavigate()

  const [api, contextHolder] = notification.useNotification();
  const [items, setItems] = useState<any>([])
  const [totalPage, setTotalPage] = useState<any>()
  const [page, setPage] = useState<any>(1)
  const defaultExpandable = {
    expandedRowRender: (record: DataType) => {
      console.log('record___', record);
      return (
        <Card title="Thông tin" bodyStyle={{ padding: 0, overflow: 'hidden' }}>
          <div className="" style={{ padding: 8, display: 'flex', flexDirection: 'row' }}>
            <Col >
              <Space direction='vertical'>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Mã GD:</Text>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Nạp:</Text>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Nhận: </Text>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Trạng thái: </Text>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Game:</Text>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Nhân vật: </Text>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Server: </Text>
                <Text style={{ textAlign: 'left', fontWeight: 'bold' }}>Thời gian: </Text>
              </Space>
            </Col>
            <Col>
              <Space direction='vertical' style={{ paddingLeft: 4 }}>
                <Text style={{ textAlign: 'left' }}> {record?.orderCode}</Text>
                <Text style={{ textAlign: 'left' }}> {formatNumberToCurrency(record?.amount)} {record?.product?.exchange?.srcExchange}</Text>
                <Text style={{ textAlign: 'left' }}> {formatNumberToCurrency(record?.transaction?.exchangeAmount)} {record?.product?.exchange?.desExchange}</Text>
                <Text style={{ textAlign: 'left', color: bgStatus[record?.status] }}> {textStatus[record?.transaction?.status]}</Text>
                <Text style={{ textAlign: 'left' }}> {record?.game?.name}</Text>
                <Text style={{ textAlign: 'left' }}> {record?.transaction?.roleName}</Text>
                <Text style={{ textAlign: 'left' }}> {record?.transaction?.serverName}</Text>
                <Text style={{ textAlign: 'left' }}> {record?.createdTime}</Text>
              </Space>
            </Col>
          </div>
        </Card>
      )
    }
  };

  const [expandable, setExpandable] = useState<ExpandableConfig<DataType> | undefined>(
    defaultExpandable,
  );
  const _query = useCallback(async (_page: number, _perPage: number, _name: any,) => {
    try {
      let { payload, error } = await query({
        UserName: _name,
        CurrentPage: _page,
        MaxResultCount: _perPage
      })
      if (!!error) return
      // setTotalPage(payload?.results?.items % _perPage == 0 ? payload?.results?.totalCount / _perPage : Math.floor((payload?.results?.totalCount / _perPage) + 1))
      // if (_page == 1) {
      //   setItems(payload?.results?.items)
      // } else {
      //   setItems((prev: any) => prev.concat(payload?.results?.items || []))
      // }
      // console.log('payload_____', payload);
    } catch (error) {
    }
  }, [])

  useEffect(() => {
    _query(1, 10, usernameLocal)
  }, [usernameLocal])


  // useMemo(() => {
  //   setItems(payload?.results?.items)
  // }, [payload])

  const bgStatus: any = {
    0: '#ffd400',
    1: 'green',
    2: '#ffd400',
    3: 'red',
    4: 'red',
    5: '#ffd400'
  }

  const textStatus: any = {
    0: 'Khởi tạo',
    1: 'Thành công',
    2: 'Đang xử lý',
    3: 'Lỗi',
    4: 'Đã hủy',
    5: 'Đang xử lý'
  }

  const columns = useMemo(() => {
    return [
      {
        title: 'Mã giao dịch',
        dataIndex: 'orderCode',
        key: 'orderCode',
        fixed: 'left',
        render: (__, _record, _idx) => {
          return <Text>{_record?.transaction?.transCode}</Text>
        }
      },

      {
        title: 'Tổng nạp',
        dataIndex: 'amount',
        key: 'amount',
        // fixed: 'left',
        render: (__, _record) => {
          return <Text style={{ fontWeight: 'bold' }}>{formatNumberToCurrency(__)} VNĐ</Text>
        }
      },
      {
        title: 'Tổng nhận',
        dataIndex: 'exchangeAmount',
        key: 'transaction',
        render: (__, _record) => {
          return <Text style={{ fontWeight: 'bold' }}>{formatNumberToCurrency(_record?.transaction?.exchangeAmount)} T-Point</Text>
        }
      },
      {
        title: 'Người nhận',
        dataIndex: 'receiver',
        key: 'receiver',
        render: (__, _record) => {
          return <Space direction='vertical'>
            <Text>Game: {_record?.game?.name}</Text>
            <Text>Nhân vật: {_record?.transaction?.roleName}</Text>
            <Text>Server: {_record?.transaction?.serverName}</Text>
          </Space>

        }
      },
      {
        title: 'Trạng thái',
        dataIndex: 'statusText',
        key: 'statusText',

        render: (__, _record) => {
          return <Text style={{ color: bgStatus[_record?.status] }}>{textStatus[_record?.status]}</Text>
        }
      },
      {
        title: 'Thời gian',
        dataIndex: 'createdTime',
        key: 'createdTime',
        align: 'center',
        render: (__, _record) => {
          return <Text>{__}</Text>
        }

      },
    ]
  }, []);

  const tableProps: TableProps<DataType> = {
    expandable,
  };

  return <ListWarapper>
    {contextHolder}
    <Layout style={{ minHeight: '100vh' }}>
      <Content className='content' >
        <Card title="Lịch sử nạp thẻ" bordered={true} style={{ width: '100%', maxWidth: 1000, margin: 'auto', marginTop: 16 }}>
          <Table
            {...tableProps}
            dataSource={payload?.results?.items || []} loading={loading} columns={columns as any} rowKey={'id'} scroll={{ x: "max-content" }}
            pagination={{
              position: ["bottomRight"], current: page, pageSize: 10, total: payload?.results?.totalCount,
              onChange: (page) => {
                setPage(page)
                navigate("/lich-su-giao-dich")
                _query(page, 10, usernameLocal)
              },
              showTotal: (total) => `Tổng số: ${total} bản ghi`,
              showSizeChanger: false
            }}
          />
        </Card>
      </Content>
    </Layout>
  </ListWarapper>

});
