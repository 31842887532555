import querystring from 'querystring'

export const listGame = (): any => {
  let endpoint = `/gateway/api/v1/games`
  return {
    api_type: 'API',
    method: 'GET',
    endpoint
  }
}

export const listPackage = ({ code }: any): any => {
  let endpoint = `/gateway/api/v1/products?gamecode=${code}`
  return {
    api_type: 'API',
    method: 'GET',
    endpoint
  }
}


export const listBank = (): any => {
  let endpoint = `/gateway/api/v1/banks`
  return {
    api_type: 'API',
    method: 'GET',
    endpoint
  }
}

export const listServer = ({ formData, GameCode, UserName }: any): any => {
  let endpoint = `/gateway/v1/game/role-list`
  return {
    api_type: 'API',
    method: 'POST',
    endpoint,
    body: formData
  }
}

export const paymentMethod = (): any => {
  let endpoint = `/gateway/api/v1/payment-methods`
  return {
    api_type: 'API',
    method: 'GET',
    endpoint
  }
}

export const createOrder = ({ formData }: any): any => {
  let endpoint = `/gateway/api/v1/order/create`
  return {
    api_type: 'API',
    method: 'POST',
    endpoint,
    body: formData
  }
}

export const listOrder = ({ UserName, CurrentPage, MaxResultCount }: { UserName: any, CurrentPage?: any, MaxResultCount?: any }): any => {
  let endpoint = `/gateway/api/v1/orders?${querystring.stringify({ UserName, CurrentPage, MaxResultCount })}`
  return {
    api_type: 'API',
    method: 'GET',
    endpoint
  }
}

export const detailOrder = ({ code, userName }: { code: any, userName: any }): any => {
  let endpoint = `/gateway/api/v1/order/detail?OrderCode=${code}&UserName=${userName}`
  return {
    api_type: 'API',
    method: 'GET',
    endpoint
  }
}


export const verifyAuthen = ({ formData }: any): any => {
  let endpoint = `/gateway/api/v1/auth/verify`
  return {
    api_type: 'API',
    method: 'POST',
    endpoint,
    body: formData
  }
}

export const listAgency = ({ page, perPage, game_code }: { page?: any, perPage?: any, game_code?: any }): any => {
  return {
    api_type: 'AGENCY',
    method: 'GET',
    endpoint: `/api/agency?${querystring.stringify({ page, perPage, game_code })}`
  }
}

export const listGameCode = (): any => {
  let endpoint = `/api/game`
  return {
    api_type: 'AGENCY',
    method: 'GET',
    endpoint
  }
}

//payment

export const categoryGameId = ({ gameId }: { gameId: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/category`
})

export const listTicket = ({ gameId, category_id }: { gameId: number, category_id: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/category/${category_id}/type`
})

export const listFormTicket = ({ gameId, category_id, category_type_id }: { gameId: number, category_id: number, category_type_id: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/category/${category_id}/type/${category_type_id}`
})

export const sendTicket = (
  payload: any
): any => {
  return {
    api_type: 'API',
    method: 'POST',
    endpoint: `/api/ticket`,
    body: payload
  }
};

export const listTicketSender = ({ gameId, status }: { gameId: any, status?: string }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/ticket?filter[status]=${status}`
})

export const ticketDetailSender = ({ gameId, ticketCode }: { gameId: number, ticketCode: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/ticket/${ticketCode}`
})


export const sendCommentTickets = ({ formValues, gameId, ticketCode }: { formValues: any, gameId: number, ticketCode: number }): any => {
  return {
    api_type: 'API',
    method: 'POST',
    endpoint: `/api/game/${gameId}/ticket/${ticketCode}/comment`,
    body: formValues
  }
};

export const getListComment = ({ gameId, ticketCode, page, perPage }: { gameId: number, ticketCode: number, page: number, perPage: number }): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/game/${gameId}/ticket/${ticketCode}/comment?${querystring.stringify({ page, perPage })}`
})

export const getListStatus = ({ }: {}): any => ({
  api_type: 'API',
  method: 'GET',
  endpoint: `/api/ticket/const`
})