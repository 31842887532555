/**
 *
 * Asynchronously loads the component for ListOrder
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ListAgency = lazyLoad(
  () => import('./index'),
  (module) => module.ListAgency,
);
