import styled from 'styled-components';

export default styled.div`
    cursor: pointer;

    .header-container {
        // -webkit-box-shadow: 2px 15px 30px 0px rgba(155,185,207,0.73);
        // box-shadow: 2px 15px 30px 0px rgba(155,185,207,0.73);
        // z-index: 10;
    }

    .header {
        display: flex;
        align-items: center; 
        background-color: white;
        max-width: 1000px;
        justify-content: space-between;
        margin: auto;
        // backgroud-color: #5ea8de
    }

    .title-hello {
        // border-right: 2px solid #f9c406;
        // border-radius: 0px !important;
        // margin-right: 16px
    }

    .ant-drawer-content-wrapper {
        // width: 100% !important;
        // box-shadow: none !important;
    }

    @media (max-width: 780px) {
        .homeTitle {
            display: none
        } 

        // .logo {
        //     display: block !important
        // }
    }

    @media (max-width: 840px) {
        .header-container {
            padding: 0px 16px;
            // backgroud-color: #5ea8de
        }

        .header {
            justify-content: space-between;
            align-items: center;
        }

        .navTitle {
            font-size: 14px,
        }
    }
`;
