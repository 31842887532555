/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'antd/dist/reset.css';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import Login from './pages/LoginPage';
import { CategoryPage } from './pages/CategoryPage';
import AuthenRoute from './components/AuthenRoute';
import MainLayout from './pages/MainLayout';
import { PaymentSuccess } from './pages/PaymentSuccess/Loadable';
import { ListAgency } from './pages/ListAgency/Loadable';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Hệ thống thanh toán games"
        defaultTitle="Payment Tepaylink - Hệ thống thanh toán games"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Payment Tepaylink - Hệ thống thanh toán games" />
      </Helmet>

      <Routes>
        <Route path="/dang-nhap" element={<Login />} />
        <Route
          path="/*"
          element={
            <AuthenRoute>
              <MainLayout />
            </AuthenRoute>
          }
        />
        {/* <Route path="*" element={<NotFoundPage />} />
        <Route path='/ticket' element={<TicketSelect />} />
        <Route path='/category' element={<CategoryPage />} />
        <Route path='/send-ticket' element={<SendTicket />} />
        <Route path='/your-tickets' element={<YourTickets />} /> */}
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
