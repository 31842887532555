export const bg = require('./img/bg.jpg')
export const cash = require('./img/cash.png')
export const account = require('./img/account.png')
export const engineering = require('./img/engineering.png')
export const bell = require('./img/bell.png')
export const cateBg = require('./img/cate-bg.jpg')
export const issue = require('./img/issue.png')
export const login = require('./img/login.png')
export const thanks = require('./img/thank.png')
export const bg_home = require('./img/bg_home.png')
export const logout_ic = require('./img/logout_ic.png')
export const game_development = require('./img/game_development.png')
export const bg_thoang_oder = require('./img/bg_thoang_order.jpg')
export const bg_login = require('./img/login_bg.png')
export const logo_tpl = require('./img/logo_tpl.png')
export const travel_agent = require('./img/travel_agent.png')
export const logo_tpl_nap = require('./img/logo_tpl_nap.png')
export const kiemhieptinh2 = require('./img/kiemhieptinh2.png')
export const napthe = require('./img/napthe.png')
export const visa_logo = require('./img/visa_logo.png')
export const atm_logo = require('./img/atm_logo.png')
export const success_ic = require('./img/success_ic.png')
export const cancelled_ic = require('./img/cancelled_ic.png')
export const logo_web = require('./img/logo_web.png')
export const direct_pay = require('./img/direct_pay.png')
export const gift = require('./img/gift.png')
export const check = require('./img/check.png')
export const best_price = require('./img/best_price.png')

